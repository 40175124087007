
import { useAppContext } from '../AppContext';
import React, { useState, useEffect } from 'react';
import ProjectDetails from './ProjectDetails';
import Select from 'react-select';
import './pricing.css';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import Transition from './Transition';
import { TypeAnimation } from 'react-type-animation';

import ScreenSize from './ScreenSize';

function Pricing() {
    const { currentLanguage, setPage } = useAppContext();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [addOnPrices, setAddOnPrices] = useState([]);
    const [addOnsChanged, setAddOnsChanged] = useState(false);
    const [serviceChanged, setServiceChanged] = useState(false);



    useEffect(() => {
        setPage('Pricing');
    }, [])

    const addOnFeaturesEN = {
        webDesign: {
            basic: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 2000 },
                { name: "Social Media Integration", price: 4000 },
            ],
            intermediate: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 3000 },
                { name: "Social Media Integration", price: 4000 },
                { name: "Analytics Integration", price: 4000 },
                { name: "Content Management System (CMS)", price: 9500 },
            ],
            advanced: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 3000 },
                { name: "Social Media Integration", price: 4000 },
                { name: "Analytics Integration", price: 4000 },                
                { name: "Content Management System (CMS)", price: 12000 },
                { name: "Multi-language Support(Amharic)", price: 10000 },
                { name: "Progressive Web App (PWA)", price: 10000 },
            ],

        },
        appDesign: {
            basic: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 3000 },

            ],
            intermediate: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 3000 },
                { name: "Social Media Integration", price: 4000 },
                { name: "Analytics Integration", price: 4000 },
                { name: "Content Management System (CMS)", price: 10000 },


            ],
            advanced: [
                { name: "SEO Optimization", price: 2000 },
                { name: "Security Enhancements", price: 3000 },
                { name: "Social Media Integration", price: 4000 },
                { name: "Analytics Integration", price: 4000 },                
                { name: "Content Management System (CMS)", price: 12000 },
                { name: "Multi-language Support(Amharic)", price: 10000 },
               
            ],



        },
        brandDesign: {

            'logo design': [
                { name: "Custom Symbol Creation", price: 7500 },
                { name: "Versatile Logo Formats", price: 10000 },
            ],

            'branding': [
                { name: "Brand Identity Guidelines", price: 12000 },
                { name: "Competitor Analysis", price: 10000 },
            ],

            'business card': [
                { name: "Die-Cut Designs", price: 1500 },
                { name: "Premium Paper Options", price: 2500 },
            ],


            'marketing material': [
                { name: "broschures/page", price: 2000 },
                { name: "Flyers/page", price: 2500 },
            ],

            'graphics design': [
                { name: "Illustrations and Icons", price: 8000 },
                { name: "Social Media Graphics", price: 5000 },
            ],


        },



    };

  
     const addOnFeaturesAM = {
        webDesign: {
            መሠረታዊ: [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "የደህንነት ማሻሻያዎች", price: 3000 },
                { name: "የሶሻል ሚድያ ውህደት", price: 4000 },
            ],
            መካከለኛ: [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "የደህንነት ማሻሻያዎች", price: 3000 },
                { name: "የሶሻል ሚድያ ውህደት", price: 4000 },
                { name: "የውሂብ(ዳታ) ትንታኔ ውህደት", price: 4000 },
                { name: "የይዘት አስተዳደር ስርዓት (CMS)", price: 9500 },
            ],
            'የላቀ / ከፍ ያለ': [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "የደህንነት ማሻሻያዎች", price: 3000 },
                { name: "የሶሻል ሚድያ ውህደት", price: 4000 },
                { name: "የውሂብ(ዳታ) ትንታኔ ውህደት", price: 4000 },
                { name: "የይዘት አስተዳደር ሥርዓት (CMS)", price: 12000 },
                { name: "የተጨማሪ ቋንቋ ድጋፍ(አማርኛ)", price: 10000 },
                { name: "ጠንካራ የድር መተግበሪያ (PWA)", price: 10000 },
            ],

        },
       
    
        appDesign: {
            መሠረታዊ: [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "የደህንነት ማሻሻያዎች", price: 3000 },

            ],
            መካከለኛ: [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "የደህንነት ማሻሻያዎች", price: 3000 },
                { name: "ሶሻል ሚዲያ ውህደት", price: 4000 },
                { name: "የውሂብ(ዳታ) ትንታኔ ውህደት", price: 4000 },                
                { name: "የይዘት አስተዳደር ሥርዓት (CMS)", price: 10000 },


            ],
            'የላቀ / ከፍ ያለ': [
                { name: "ለSEO ማመቻቸት", price: 2000 },
                { name: "ደህንነት ማሻሻያዎች", price: 3000 },
                { name: "ሶሻል ሚዲያ ውህደት", price: 4000 },
                { name: "የውሂብ(ዳታ) ትንታኔ ውህደት", price: 4000 },                
                { name: "የይዘት አስተዳደር ሥርዓት (CMS)", price: 12000 },
                { name: "የተጨማሪ ቋንቋ ድጋፍ(አማርኛ)", price: 10000 },
            ],

        },

    
    
        brandDesign: {


            'የድርጅት ዓርማ': [
                { name: "ብጁ ምልክት መፍጠር", price: 7500 },
                { name: "ሁለገብ የአርማ ቅርጸቶች", price: 10000 },
            ],

            'ብራንዲንግ': [
                { name: "የምርት መለያ መመሪያዎች", price: 12000 },
                { name: "የሥራ ተፎካካሪ ትንታኔ", price: 10000 },
            ],

            'ቢዝነስ ካርድ': [
                { name: "Die-Cut ንድፎች", price: 1500 },
                { name: "ፕሪሚየም የወረቀት አማራጮች", price: 2500 },
            ],
           
            'ግራፊክስ ዲዛይን': [
                { name: "ኢለስትሬሽንና አይከኖች", price: 8000 },
                { name: "ማህበራዊ ሚዲያ ግራፊክስ", price: 5000 },
            ],


        },


    };
    

    const [addOnFeatures, setAddOnFeatures] = useState(currentLanguage === 'English' ? addOnFeaturesEN : addOnFeaturesAM);
    useEffect(() => {
        setSelectedOption(null);
        setSelectedService(null);
        setSelectedAddOns([]);
        

        const updatedAddOnFeatures = currentLanguage === 'English' ? addOnFeaturesEN : addOnFeaturesAM;
        setAddOnFeatures(updatedAddOnFeatures);
      

    }, [currentLanguage])



    const optionsEN = [
        { value: 'webDesign', label: 'Web Design' },
        { value: 'appDesign', label: 'App Design' },
        { value: 'brandDesign', label: 'Brand Design' },
        // {value: 'officeSoftware', label: 'Office Software Solutions'},
        // { value: 'aiAdvancements', label: 'AI Powered Advancements' },
    ];
    const optionsAM = [
        { value: 'webDesign', label: 'ዌብ ዲዛይን' },
        { value: 'appDesign', label: 'መተግበሪያ ዲዛይን' },
        { value: 'brandDesign', label: 'ብራንድ ዲዛይን' },
        // {value: 'officeSoftware', label: 'Office Software Solutions'},
        // { value: 'aiAdvancements', label: 'AI Powered Advancements' },
    ];
    const options = currentLanguage === 'English' ? optionsEN : optionsAM;

    const serviceOptionsEN = {
        webDesign: ['Basic', 'Intermediate', 'Advanced'],
        appDesign: ['Basic', 'Intermediate', 'Advanced'],
        brandDesign: ['Branding', 'Logo Design', , 'Business Card', 'Graphics Design',
        ],


    };

    const serviceOptionsAM = {
        webDesign: ['መሠረታዊ', 'መካከለኛ', 'የላቀ / ከፍ ያለ'],
        appDesign: ['መሠረታዊ', 'መካከለኛ', 'የላቀ / ከፍ ያለ'],
        brandDesign: ['ብራንዲንግ', 'የድርጅት ዓርማ', , 'ቢዝነስ ካርድ', 'ግራፊክስ ዲዛይን', 
        ],


    };

    const serviceOptions = currentLanguage === 'English' ? serviceOptionsEN : serviceOptionsAM;


    const currencyCode = currentLanguage === 'English' ? 'en-ET' : 'am-ET'; // Assuming 'am-ET' is not a valid locale, replace it with the appropriate locale for Amharic if available

    
    


    const handleDropdownChange = (selected) => {
        if (selected.value) {
            setSelectedOption(selected.value);
        } else {
            setSelectedOption('');
        }

        setSelectedService(null);

        setSelectedAddOns([]);
    };

    const priceViewer = (selectedOption, selectedService) => {

        if (currentLanguage === 'English') {
            switch (selectedOption) {

                case 'webDesign':
                    // return selectedService === 'Basic' ? 15000 : selectedService === 'Intermediate' ? 30000 : 45000;
                    return selectedService === 'Basic' ? 35000 : selectedService === 'Intermediate' ? 50000 : 65000;
                case 'appDesign':
                    // return selectedService === 'Basic' ? 22500 : selectedService === 'Intermediate' ? 37500 : 50000;
                    return selectedService === 'Basic' ? 49500 : selectedService === 'Intermediate' ? 69500 : 90000;
                case 'brandDesign':
                    // return selectedService === 'Logo Design' ? 2500 : selectedService === 'Branding' ? 7500 : selectedService === 'Business Card' ? 1500 :
                    //     selectedService === 'Marketing Materials' ? 2500 : 7500;
                    return selectedService === 'Logo Design' ? 7500 : selectedService === 'Branding' ? 12000 : 
                    selectedService === 'Business Card' ? 1500 : 7500;
                default:
                    return 0;
            }

        }
        else {
            switch (selectedOption) {

                case 'webDesign':
                    // return selectedService === 'መሠረታዊ' ? 15000 : selectedService === 'መካከለኛ' ? 30000 : 45000;
                    return selectedService === 'መሠረታዊ' ? 35000 : selectedService === 'መካከለኛ' ? 50000 : 65000;
                case 'appDesign':
                    // return selectedService === 'መሠረታዊ' ? 22500 : selectedService === 'መካከለኛ' ? 37500 : 50000;
                    return selectedService === 'መሠረታዊ' ? 49500 : selectedService === 'መካከለኛ' ? 69500 : 90000;
                case 'brandDesign':
                    // return selectedService === 'የድርጅት ዓርማ' ? 2500 : selectedService === 'ብራንዲንግ' ? 7500 : selectedService === 'ቢዝነስ ካርድ' ? 1500 :
                    //     selectedService === 'መረጃ/ማስታወቂያ ሥራ' ? 2500 : 7500;
                    return selectedService === 'የድርጅት ዓርማ' ? 7500 : selectedService === 'ብራንዲንግ' ? 12000 : selectedService === 'ቢዝነስ ካርድ' ? 1500 :
                    7500;
                default:
                    return 0;
            }
        }




    }

    const priceCalculator = (selectedService, selectedAddOns, addOnPrices) => {
        let totalPrice = selectedService ? selectedService.price : 0;

        if (selectedAddOns && addOnPrices) {
            addOnPrices.forEach((addOnPrice) => {
                totalPrice += addOnPrice;
            });
        }

        return totalPrice;
    };

    const [displayedTotalPrice, setDisplayedTotalPrice] = useState(0);

    const calculateTotalPrice = () => {
        // Calculate the total price based on selectedService, selectedAddOns, etc.
        const totalPrice = priceCalculator(selectedService, selectedAddOns, addOnPrices);
        return totalPrice;
    };

    useEffect(() => {
        // Incrementally update the displayedTotalPrice until it reaches the calculated total price
        const calculatedTotalPrice = calculateTotalPrice();

        let currentDisplayedPrice;;
        // Check if selectedService or selectedAddOns changed so incrementing number can start from where it started.
        if (serviceChanged) {
            currentDisplayedPrice = 0;
            setServiceChanged(false);
        } else if (addOnsChanged) {
            currentDisplayedPrice = displayedTotalPrice;
            setAddOnsChanged(false);
        }



        // currentDisplayedPrice = prevSelectedService === selectedService ?  displayedTotalPrice:0;
        const increment = Math.ceil(calculatedTotalPrice / 20); // Adjust the increment value as needed

        const intervalId = setInterval(() => {
            currentDisplayedPrice += increment;

            // Ensure the displayed price doesn't exceed the calculated total price
            if (currentDisplayedPrice >= calculatedTotalPrice) {
                currentDisplayedPrice = calculatedTotalPrice;
                clearInterval(intervalId); // Stop the interval once we reach the final value
            }

            setDisplayedTotalPrice(currentDisplayedPrice);
        }, 50); // Adjust the interval duration as needed

        // Cleanup the interval when the component unmounts or when selectedService, selectedAddOns, etc., change
        return () => clearInterval(intervalId);
    }, [selectedService, selectedAddOns, addOnPrices]);

    const handleRadioButtonChange = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Update the selected service in the state

            setSelectedService({ service: value, price: priceViewer(selectedOption, value) });
            setServiceChanged(true);
            // Clear selected add-ons when changing the radio button
            setSelectedAddOns([]);
            setAddOnPrices([]);
        } else {
            // Deselect the service by setting the state to null
            setSelectedService(null);
            // Clear selected add-ons when changing the radio button
            setSelectedAddOns([]);
            setAddOnPrices([]);
        }
    };



    // Function to update add-on prices in the main component
    const updateAddOnPrices = (prices) => {
        setAddOnPrices(prices);
    };


   
    
    const ServiceAddOns = ({ option, addOns, updateAddOnPrices }) => {

       

        const handleCheckboxChange = (addOn) => {
            const updatedAddOns = selectedAddOns.includes(addOn)
                ? selectedAddOns.filter((selectedFeature) => selectedFeature !== addOn)
                : [...selectedAddOns, addOn];

            setSelectedAddOns(updatedAddOns);
            setAddOnsChanged(true);


            // Update add-on prices in the main component
            updateAddOnPrices(updatedAddOns.map((selectedFeature) => addOnFeatures[option][addOns.toLowerCase()].find(feature => feature.name === selectedFeature).price));


        };

        const subheader_title_features = currentLanguage === 'English' ? "Additional Features" : "ተጨማሪ አገልግሎቶች";

        return (
            selectedService && option && addOns &&(
                <div style={{
                    padding: '5px', borderRadius: '5px', fontSize: '14px',
                    border: `${addOnInView ? addOnAnimationCompleted ? '1px' : '2px' : '1px'} solid ${addOnInView ? addOnAnimationCompleted ? '#474554' : '#4ddde0' : '#474554'}`
                }} className='add-ons' ref={addOnRef}>
                    <h3 style={{
                        textDecoration: 'underline', color: '#c7e6d7', backgroundColor: '#474545', padding: '5px 0', fontwid
                            : '400', margin: 'auto', textAlign: 'center'
                    }}>{subheader_title_features} </h3>
        
                    {addOnFeatures[option][addOns.toLowerCase()].map((addOn, index) => (
                        <div key={index} className='checkbox-options'>
                            <input
                                type="checkbox"
                                id={addOn.name}
                                onChange={() => handleCheckboxChange(addOn.name)}
                                checked={selectedAddOns.includes(addOn.name)}
                                onClick={handleAddOnClick}
                            />
                            <label htmlFor={addOn.name}>{addOn.name}</label>
                        </div>
                    ))}
        
                </div>
            )
        );
        
    };


    const [radioClicked, setRadioClicked] = useState(false);
    const [addOnClicked, setAddOnClicked] = useState(false);
    const [selectClicked, setSelectClicked] = useState(false);


    const selectRef = useRef();
    const radioRef = useRef();
    const addOnRef = useRef();

    const [selectInView, setSelectInView] = useState(false);
    const [radioInView, setRadioInView] = useState(false);
    const [addOnInView, setAddOnInView] = useState(false);



    const handleScrollForRef = (ref, setInView) => {
        return () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
                setInView(isVisible);
            }
        };
    };

    useEffect(() => {
        const handleScrollSelect = handleScrollForRef(selectRef, setSelectInView);
        window.addEventListener('scroll', handleScrollSelect);
        handleScrollSelect(); // Check initial visibility for select

        return () => {
            window.removeEventListener('scroll', handleScrollSelect);
        };
    }, []);

    useEffect(() => {
        if (radioRef.current) {
            const handleScrollRadio = handleScrollForRef(radioRef, setRadioInView);
            window.addEventListener('scroll', handleScrollRadio);
            handleScrollRadio(); // Check initial visibility for radio

            return () => {
                window.removeEventListener('scroll', handleScrollRadio);
            };
        }
    }, [selectedOption]);

    const [selectAnimationCompleted, setSelectAnimationCompleted] = useState(false);
    const [radioAnimationCompleted, setRadioAnimationCompleted] = useState(false);
    const [addOnAnimationCompleted, setAddOnAnimationCompleted] = useState(false);


    useEffect(() => {
        if (selectInView && !selectAnimationCompleted && !selectClicked) {


            let animationCount = 0;
            const intervalId = setInterval(() => {

                setSelectAnimationCompleted((prev) => !prev);
                animationCount++;

                if (animationCount >= 6) {
                    // Toggling is complete, set radioInView to false
                    clearInterval(intervalId);
                    setSelectInView(false);
                    setSelectAnimationCompleted(true);
                }
            }, 500);

            return () => {
                // Clean up
                clearInterval(intervalId);
            };
        }
    }, [selectInView]);

    useEffect(() => {


        if (radioInView && !radioAnimationCompleted && !radioClicked) {


            let animationCount = 0;
            const intervalId = setInterval(() => {

                // setRadioInView((prev) => !prev);
                setRadioAnimationCompleted((prev) => !prev);
                animationCount++;


                if (animationCount >= 3) {
                    // Toggling is complete, set radioInView to false
                    clearInterval(intervalId);
                    setRadioInView(false);
                    setRadioAnimationCompleted(true);
                }
            }, 400); // Adjust the interval duration as needed

            return () => {
                // Clean up

                clearInterval(intervalId);
            };
        }
    }, [radioInView]);




    useEffect(() => {
        if (addOnInView && !addOnAnimationCompleted && !addOnClicked) {


            let animationCount = 0;
            const intervalId = setInterval(() => {
                // setRadioInView((prev) => !prev);
                setAddOnAnimationCompleted((prev) => !prev);
                animationCount++;

                if (animationCount >= 3) {
                    // Toggling is complete, set radioInView to false
                    clearInterval(intervalId);
                    setAddOnInView(false);
                    setAddOnAnimationCompleted(true);
                }
            }, 300);

            return () => {
                // Clean up
                clearInterval(intervalId);
            };
        }
    }, [addOnInView]);


    useEffect(() => {
        if (addOnRef.current) {
            const handleScrollRadio = handleScrollForRef(addOnRef, setAddOnInView);
            window.addEventListener('scroll', handleScrollRadio);
            handleScrollRadio(); // Check initial visibility for radio

            return () => {
                window.removeEventListener('scroll', handleScrollRadio);
            };
        }
    }, [selectedService]);



    const handleRadioClick = () => {
        setRadioClicked(true);
    };

    const handleAddOnClick = () => {
        setAddOnClicked(true);
    };

    const handleSelectClick = () => {
        setSelectClicked(true);
    };


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#c7e6d7' : 'white',
            color: '#474554',
            ':hover': {
                backgroundColor: '#c7e6d7',
                color: '#474554',
            },
        }),
        control: (provided) => ({
            ...provided,
            padding: '8px',
            border: `${selectInView ? selectAnimationCompleted ? '1px' : '2px' : '1px'} solid ${selectInView ? selectAnimationCompleted ? '#474554' : '#4ddde0' : '#474554'}`,
            borderRadius: '4px',
            backgroundColor: 'white',
            cursor: 'pointer',
            fontWeight: '400',
            fontSize: '16px',
            maxWidth: '400px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#474554',
            ':hover': {
                backgroundColor: '#474554',
                color: '#c7e6d7',
            },
        }),
    };

    const price_total = currentLanguage === 'English' ? 'Price Total: ' : 'ጠቅላላ ዋጋ፤ ';
    const serviceName_table = currentLanguage === 'English' ? 'Service' : 'አገልግሎት';
    const price_table = currentLanguage === 'English' ? 'Price' : 'ዋጋ';
    const selectedServiceType = currentLanguage === 'English' ? 'Selected service type' : 'የተመረጠው አገልግሎት';
    const choose_service = currentLanguage === 'English' ? 'Please choose a service:' : 'እባክዎን አገልግሎትዎን ይምረጡ';
    const header_title = currentLanguage === 'English' ? 'PRICE MATTERS' : 'የሂሳብ ጉዳይ';
    const subheader_title = currentLanguage === 'English' ? 'Price Calculator' : 'የዋጋ ማሳያ';
    const pricepage_motto = currentLanguage === 'English' ?
        'Excited to explore how we can collaborate? Discover the perfect solution for your needs on our ' +
        'Pricing page. Whether you\'re looking for a tailored package or have specific requirements, let\'s ' +
        'find the ideal plan to elevate your digital journey. Your success continues here!' :
        'እንዴት መተባበር እንደምንችል ለማወቅ ወስነዋል? እንግዲያውስ በእኛ የዋጋ አሰጣጥ ገጽ ላይ ለፍላጎቶችዎ ትክክለኛውን መፍትሄ ያግኙ። የተበጀ ፓኬጅም ሆነ ለየት ያለ ' +
        'መስፈርቶች ካሎት፣ የዲጂታል ጉዞዎን ከፍ ለማድረግ ትክክለኛውን እቅድ አብራን እናቅድ። የስኬት ታሪክዎ እዚህም ይቀጥላል!';


    const price_footnote = currentLanguage === 'English' ?
        <span>
            <span style={{ fontWeight: 'bold' }}>
                At the moment, we have set out pricings for website design, app design and brand design technology solutions.
            </span><br /><br />
            Software Solutions and AI powered advancement services and products require detailed and careful analysis of what your
            business/institute needs.<br />If you're looking for Software Solutions or AI powered advancement services/products, then please reach us through email or phone call at the earliest so we set out
            clear, clean and customized plans just for you!
        </span>
        :
        <span>
            <span style={{ fontWeight: 'bold' }}>ለጊዜው ለዌብሳይት ዲዛይን፣ ለስልክ መተግበሪያ ዲዛይን እና ለብራንድ ዲዛይን ቴክኖሎጂ መፍትሄዎች ዋጋዎችን አውጥተናል፡፡</span><br /><br />
            የቢሮ ሶፍትዌር እና የAI መርህ መፍትሄ አገልግሎቶች እና ምርቶች ንግድዎ/ኢንስቲትዩትዎ የሚፈልጓቸውን ነገሮች ዝርዝር እና ጥንቃቄ የተሞላበት ትንተና ያስፈልጋቸዋል።<br />
            የቢሮ ሶፍትዌር ወይም የAI መርህ የእድገት አገልግሎቶችን/ምርቶችን የፈለጉ ከሆነ እባክዎን ለእርስዎ ብቻ ግልጽ፣ ንፁህ እና ብጁ ዕቅዶችን እናዘጋጅ ዘንድ በኢሜይላችን ይጻፉልን ወይም በስልካችን ይደውሉልን።
        </span>;




    const ServiceOptions = () => {
        return (
            <div className='service-options'>
                <div style={{ position: 'relative', }}
                    className='service-selection'>
                    <label htmlFor="option-select" style={{ display: 'inline-block', }}>{choose_service}</label>

                    <motion.div ref={selectRef}>
                        <Select
                            id="serviceDropDown"
                            options={options}
                            value={selectedOption}
                            onChange={(selected) => handleDropdownChange(selected)}
                            onClick={handleSelectClick}
                            placeholder={selectedOption ?
                                (currentLanguage === 'English' ?
                                    (selectedOption === "webDesign" ? "Web Design" :
                                        selectedOption === "appDesign" ? "App Design" : "Brand Design")
                                    :
                                    (selectedOption === "webDesign" ? "ዌብሳይት ዲዛይን" :
                                        selectedOption === "appDesign" ? "ሞባይል መተግበሪያ ዲዛይን" : "ብራንድ ዲዛይን")
                                )
                                :
                                (currentLanguage === 'English' ? "Please choose a service" : "አገልግሎትዎን ይምረጡ")
                            }

                            isClearable={true}
                            styles={customStyles}
                            className='select-service-container'




                        />
                    </motion.div>
                </div>

                <div>
                    {selectedOption && (

                        <div className='radio-options' >
                            {serviceOptions[selectedOption].map((service, index) => (

                                <React.Fragment key={index}>
                                    <motion.div ref={radioRef}>
                                        <input
                                            type="radio"
                                            id={service}
                                            name="serviceOption"
                                            value={service}
                                            onChange={handleRadioButtonChange}
                                            onClick={handleRadioClick}
                                            checked={selectedService && selectedService.service === service}

                                        />
                                        <label htmlFor={service} className={selectedService && selectedService.service === service ? 'radio-checked' : ''}
                                            style={{
                                                border: `${radioInView ? radioAnimationCompleted ? '1px' : '2px' : '1px'} solid ${radioInView ? radioAnimationCompleted ? '#474554' : '#4ddde0' : '#474554'}`,
                                            }}>

                                            {service}
                                        </label>
                                    </motion.div>
                                </React.Fragment>

                            ))}
                        </div>

                    )}
                </div>
                {selectedOption && (
                    <div className='selection-info'>
                        <label htmlFor="option-select" style={{ display: 'inline-block' }}>
                            {selectedServiceType} - <span style={{ fontWeight: 'bold' }}>{options.find(option => option.value === selectedOption).label}</span>
                            {selectedService && <span style={{ fontWeight: 'bold' }}> | {selectedService.service}</span>}
                        </label>
                    </div>)}

                <div className='service-table'>

                    <div className='service-details-container'>
                        {selectedOption && (

                            <>
                                {selectedService && <ProjectDetails selectedService={selectedService.service} selectedOption={selectedOption} />}
                            </>
                        )}
                    </div>
                    <div className='service-addons'>
                        {selectedService && selectedService && (
                            <ServiceAddOns option={selectedOption} addOns={selectedService.service} updateAddOnPrices={updateAddOnPrices} />
                        )}

                    </div>

                    <div className='pricing-container' >
                        {selectedOption && (
                            <div className="price-viewer">
                                <table className="price-table">
                                    <thead>
                                        <tr>
                                            <th>{serviceName_table}</th>
                                            <th>{price_table}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedService && selectedAddOns && (
                                            <tr>
                                                <td style={{ backgroundColor: '#C7E6D7' }}>{selectedService.service}</td>
                                                <td>{selectedService.price.toLocaleString('en-ET', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\.00$/, '')}</td>
                                            </tr>
                                        )}

                                        {selectedService && selectedAddOns && addOnPrices.map((price, index) => (
                                            <tr key={index}>
                                                <td>{selectedAddOns[index]}</td>
                                                <td>{price.toLocaleString('en-ET', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\.00$/, '')}</td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                        )}






                        {selectedOption && (
                            <div className='total-price'>
                                <div style={{ backgroundColor: '#C7E6D7', padding: '10px', textAlign: 'center' }}>
                                    {Number.isFinite(displayedTotalPrice) ? (
                                        <>
                                            <span>{price_total} </span>
                                            {/* {displayedTotalPrice.toLocaleString('en-ET', { style: 'currency', currency: 'ETB' })} */}
                                            {displayedTotalPrice.toLocaleString(currencyCode, { style: 'currency', currency: 'ETB' })}
                                        </>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4CAF50" width="24px" height="24px">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M2 4h20c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2zm2 2v10h16V6H4zm8 2c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
                                        </svg>
                                    )}
                                </div>




                            </div>
                        )}

                    </div>
                </div>

                {/* <p className='pricing-note' style={{ fontWeight: '600' }}>Please note that prices might vary based on your special needs.</p> */}
                <p style={{ border: '2px solid #ccc', margin: '12px auto', }} className='pricing-note'>
                    {price_footnote}

                </p>


            </div>
        );
    };

    return (
        <div className='main-container'>

            <div className='sub-container'>
                {/* <ScreenSize /> */}


                <div className="motto">
                    <h1>{header_title}</h1>
                    <p>{pricepage_motto}</p>
                </div>
                <div style={{
                    textAlign: 'center', backgroundColor: '#474554',
                    color: '#c7e6d7', display: 'flex', justifyContent: 'center',
                    textAlign: 'center', padding: '20px',
                    margin: 'auto'
                }}
                    className='pricing-promo-container'>
                    {currentLanguage === 'English' && (
                        <TypeAnimation
                            sequence={[

                                'We\'re affordable!',
                                1000,
                                'We\'re on time!',
                                1000,
                                'Turbo charge your digital presence!',
                                1000,
                                'Change . Impact . Growth .',
                                1000,

                            ]}
                            wrapper="span"
                            speed={50}
                            style={{ display: 'inline-block', textTransform: 'capitalize' }}


                            repeat={Infinity}
                        />

                    )}
                    {currentLanguage === 'አማርኛ' && (
                        <TypeAnimation
                            sequence={[

                                'ዋጋችን ርካሽ!',
                                1000,
                                'ሥራችን ፈጣን!',
                                1000,
                                'የዲጂታል ንግድና አቅርቦትዎን ከፍ ያድርጉ!',
                                1000,
                                'ለውጥ ፤ ተፅዕኖ ፤ አድገት ',
                                1000,

                            ]}
                            wrapper="span"
                            speed={50}
                            style={{ display: 'inline-block', textTransform: 'capitalize' }}


                            repeat={Infinity}
                        />

                    )}

                </div>
                <div className='pricing'>
                    <h2 className='sub-header'>{subheader_title}</h2>
                </div>
                <ServiceOptions />
                <Footer />
            </div>


        </div>
    );
}

export default Transition(Pricing, "Pricing");
